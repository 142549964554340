.root {
    width: 100%;

    .highestBidderContainerMobile {
        width: 100%;
        justify-content: center;
        margin-bottom: 17px;

        .highestBidderContainer {
            border-radius: 64px;
            background-color: #2e7d32;
            
            .highestBidderLabel {
                font-size: 13px;
                font-weight: 300;
                color: white;
                margin: 0 32px;
            }
        }
    }

    .outbidContainerMobile {
        width: 100%;
        justify-content: center;
        margin-bottom: 17px;

        .outbidContainer {
            border-radius: 64px;
            background-color: #bc251b;
            
            .outbidLabel {
                font-size: 13px;
                font-weight: 300;
                color: white;
                margin: 0 32px;
            }
        }
    }

    .mainInfoContainer {
        justify-content: space-between;
        align-items: center;

        .nameContainer {

            .brandLabel {
                font-size: 48px;
                font-weight: 300;
                line-height: 65px;
                color: #1d3053;
                text-transform: uppercase;
            }

            .brandLabelMobile {
                font-size: 43px;
                font-weight: 300;
                line-height: 50px;
                color: #1d3053;
                text-transform: uppercase;
            }

            .modelLabel {
                font-size: 28px;
                font-weight: 200;
                color: rgba(#000000, 0.6);
                text-transform: uppercase;
            }

            .modelLabelMobile {
                margin-top: 3px;
                font-size: 25px;
                font-weight: 200;
                color: rgba(#000000, 0.6);
                text-transform: uppercase;
            }

            .yearLabel {
                font-size: 22px;
                font-weight: 400;
                color: rgba(#000000, 0.6);
            }

            .yearLabelMobile {
                margin-top: 2px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(#000000, 0.6);
            }
        }

        .bidDetailsContainer {

            .highestBidderContainer {
                justify-content: center;
                border-radius: 64px;
                background-color: #2e7d32;
                width: 100%;
                margin-bottom: 24px;                

                .highestBidderLabel {
                    font-size: 13px;
                    font-weight: 300;
                    color: white;
                    margin: 0 28px;
                }
            }

            .outbidContainer {
                justify-content: center;
                border-radius: 64px;
                background-color: #bc251b;
                width: 100%;
                margin-bottom: 24px;                

                .outbidLabel {
                    font-size: 13px;
                    font-weight: 300;
                    color: white;
                    margin: 0 28px;
                }
            }

            .bidDetailsLowerContainer {
                align-items: center;
                justify-content: center;
            }

            .currentBidContainer {

                .currentBidLabel {
                    font-size: 13px;
                    font-weight: 400;
                    color: rgba(#1d3053, 0.6);
                }

                .currentBidValue {
                    font-size: 22px;
                    font-weight: 300;
                    color: #1d3053;
                }

                .currentBidNoValue {
                    font-size: 18px;
                    font-weight: 200;
                    color: #1d3053;
                }
            }

            .currentBidContainerMobile {

                .currentBidLabel {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(#1d3053, 0.78);
                }

                .currentBidValue {
                    margin-top: -5px;
                    font-size: 20px;
                    font-weight: 300;
                    color: #1d3053;
                }

                .currentBidNoValue {
                    margin-top: -5px;
                    font-size: 16px;
                    font-weight: 200;
                    color: #1d3053;
                }
            }

            .spacerContainer {
                margin: 0 24px;

                .spacer {
                    width: 1px;
                    background-color: #A1A1A1;
                    height: 44px;
                }
            }

            .spacerContainerMobile {
                margin: 9px 0 6px 0;

                .spacer {
                    height: 2px;
                    background-color: #A1A1A1;
                    width: 100%;
                }
            }

            .favouritesContainer {
                align-items: center;

                .bidCountValue {
                    font-size: 16px;
                    font-weight: 300;
                    color: #a38c5e;
                    margin-right: 3px;
                }

                .gavelIcon {
                    width: 26px;
                    height: 26px;
                }

                .gavelBase {
                    margin-left: -10px;
                    font-size: 28px;
                    font-weight: 400;
                    color: #a38c5e;
                }

                .favouritesCountValue {
                    margin-left: 24px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #a38c5e;
                }

                .favouriteIcon {
                    margin-left: 4px;
                    width: 18px;
                    height: 18px;
                    color: #a38c5e;
                }
            }

            .favouritesContainerMobile {
                justify-content: center;
                align-items: center;

                .bidCountValue {
                    font-size: 14px;
                    font-weight: 300;
                    color: #a38c5e;
                    margin-right: 3px;
                }

                .gavelIcon {
                    width: 22px;
                    height: 22px;
                }

                .gavelBase {
                    margin-left: -8px;
                    font-size: 22px;
                    font-weight: 400;
                    color: #a38c5e;
                }

                .favouritesCountValue {
                    margin-left: 21px;
                    font-size: 14px;
                    font-weight: 300;
                    color: #a38c5e;
                }

                .favouriteIcon {
                    margin-left: 3px;
                    width: 16px;
                    height: 16px;
                    color: #a38c5e;
                }
            }            
        }
    }

    .tabsContainer {
        width: 100%;
        margin-top: 25px;

        .tabContentContainer {
            :global(.MuiBox-root) {
                padding: 17px 8px;
            }

            .summaryText {
                font-size: 14px;
                font-weight: 300;
                color: #1d3053;

                ul {
                    list-style: none;

                    li::before {
                        content: "\2022";
                        color: #D2AE6D;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }

            .summarySpacer {
                height: 12px;
            }

            .summaryLineContainer {
                width: 100%;
                align-items: center;
                margin: 16px 0;

                .summaryLine {
                    height: 1px;
                    width: 60%;
                    margin: 12px 0;
                    background-color: #E0E0E0;
                }
            }

            .suffixText {
                font-size: 14px;
                font-weight: 300;
                color: #1d3053;
                margin-bottom: 12px;
            }

            .specificationContainer {
                display: grid;
                grid-template-columns: 50% 50%;
                width: 100%;

                .specificationValueContainer {
                    margin-top: 4px;
                    margin-bottom: 19px;
                    margin-left: 8px;
                    align-items: center;

                    .specificationLabel {
                        color: rgba(#1D3053C7, 0.78);
                        font-size: 13px;
                        font-weight: bold;
                        text-transform: none;
                    }

                    .specificationValue {
                        color: #111D32;
                        font-size: 13px;
                        font-weight: 300;
                        text-transform: none;
                        margin-left: 7px;
                    }                    
                }
            }

            .sellerDetailsContainer {
                display: grid;
                grid-template-columns: 50% 50%;
                width: 100%;

                .sellerDetailsValueContainer {
                    margin-top: 4px;
                    margin-bottom: 19px;
                    margin-left: 8px;

                    .sellerDetailsLabel {
                        color: rgba(#1D3053C7, 0.78);
                        font-size: 13px;
                        font-weight: bold;
                        text-transform: none;
                    }

                    .sellerDetailsValue {
                        color: #172642;
                        font-size: 13px;
                        font-weight: 300;
                        text-transform: none;
                        margin-left: 7px;
                    }

                    .gavelIcon {
                        margin-left: 8px;
                        margin-top: 4px;
                        width: 15px;
                        height: 15px;
                    }

                    .cartIcon {
                        margin-left: 4px;
                        margin-top: 4px;
                        width: 15px;
                        height: 14px;
                    }
                }
            }                    
        }
    }

    .emailContainer {
        margin: 32px 8px 0 8px;
        align-items: left;

        .emailText {
            color: #1D3053;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 12px;
        }

        .emailFormContainer {
            width: 100%;
            align-items: center;

            .emailForm {
                margin-top: -14px;
                margin-left: -12px;
                width: 330px;
                height: 280px;
                border: 0;            
            }
        }
    }

    .createBidContainer {
        align-items: center;
        margin-top: 80px;
        width: 100%;

        .minBidContainer {
            width: 75%;

            .bidEntryContainer {
                align-items: center;
                width: 100%;

                .currencyLabel {
                    margin-top: 16px;
                    font-size: 16px;
                    font-weight: 300;
                    color: rgba(#152850, 0.5);
                }

                .inputContainer {
                    flex-grow: 1;
                    margin-left: 8px;
                    
                    :global(.MuiInput-input) {
                        color: #1d3053;
                    }

                    .minBidLabel {
                        text-transform: none;
                        font-size: 11px;
                        font-weight: 400;
                        color: rgba(#1D3053C7, 0.78);
                        margin-top: 6px;
                        margin-bottom: 4px;
                    }

                    .bidInput {
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .clearButton {
                        padding: 0;
                        margin-right: 3px;
                    }
                }                        
            }

            .bidButtonsContainer {
                justify-content: flex-end;
                margin-top: 12px;

                :global(.Mui-disabled) {
                    background-color: #BABEC7 !important;
                }

                .addFavouritesButton {
                    border: 1px solid #778398;
                    border-radius: 2px;
                    background-color: white;
                    padding: 8px;

                    .addFavouritesText {
                        font-size: 13px;
                        font-weight: 300;
                        color: #1d3053;
                        text-transform: none;
                    }
                }

                .placeBidButton {
                    margin-left: 8px;
                    border: 1px solid #1d3053;
                    border-radius: 2px;
                    background-color: #1d3053;
                    padding: 8px;

                    .placeBidText {
                        font-size: 13px;
                        font-weight: 300;
                        color: white;
                        text-transform: none;
                    }
                }
            }
        }
    }

    .auctionEndsContainer {
        margin-left: 24px;
        height: 100%;

        .auctionEndsLabel {
            text-transform: none;
            font-size: 11px;
            font-weight: 400;
            color: rgba(#1D3053C7, 0.78);
            margin-top: 6px;
            margin-bottom: 4px;
            text-align: center;
        }

        .timeRemainingContainer {
            color: #e91b1b;

            .timerIcon {
                width: 24px;
                height: 24px;
            }

            .timeRemainingText {
                color: #e91b1b;
                font-size: 16px;
                font-weight: 300;
                margin-left: 4px;
            }
        }
    }

    .auctionStartsContainerMobile {
        margin-top: 16px;
        margin-bottom: 32px;
        width: 100%;
        justify-content: flex-end;

        .contentsContainer {
            .auctionStartsLabel {
                text-transform: none;
                font-size: 11px;
                font-weight: 400;
                color: rgba(#1D3053C7, 0.78);
                margin-top: 6px;
                margin-bottom: 4px;
            }

            .auctionStartsValue {
                color: #000000;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }

    .auctionEndsContainerMobile {
        margin-top: 16px;
        width: 100%;
        justify-content: flex-end;

        .contentsContainer {
            .auctionEndsLabel {
                text-transform: none;
                font-size: 11px;
                font-weight: 400;
                color: rgba(#1D3053C7, 0.78);
                margin-top: 6px;
                margin-bottom: 4px;
            }

            .timeRemainingContainer {
                color: #e91b1b;

                .timerIcon {
                    width: 24px;
                    height: 24px;
                }

                .timeRemainingText {
                    color: #e91b1b;
                    font-size: 16px;
                    font-weight: 300;
                    margin-left: 4px;
                }
            }
        }
    }

    .bidHistoryContainer {
        margin-top: 24px;

        .expandIcon {
            color: rgba(#000000, 0.54);
        }

        .titleLabel {
            font-size: 14px;
            font-weight: 300;
            color: #1d3053;
        }

        .bidHistoryNoItemsContainer {
            width: 100%;
            height: 60px;
            align-items: center;
            justify-content: center;

            .bidHistoryNoItems {
                margin: 0px 8px;
                font-size: 13px;
                font-weight: 300;
                color: rgba(#000000, 0.7);
            }
        }        
    }

    .conditionContainer {
        margin-top: 24px;

        .expandIcon {
            color: rgba(#000000, 0.54);
        }

        .titleLabel {
            font-size: 14px;
            font-weight: 300;
            color: #1d3053;
        }

        .conditionContentContainer {

            .otherImagesContainer {
                display: flex;
                flex-direction: row;
                overflow-x: hidden;
                overflow-y: hidden;
                width: 208px;
                height: 43px;
                margin: 12px 8px 0 8px;
                position: relative;
    
                .thumbnailImageFirst {
                    border-radius: 4px;
                    width: 64px;
                    height: 43px;
                }
    
                .thumbnailImage {
                    border-radius: 4px;
                    margin-left: 8px;
                    width: 64px;
                    height: 43px;
                }
    
                .moreContainer {
                    border-radius: 4px;
                    background-color: rgba(#000000, 0.57);
                    width: 64px;
                    height: 43px;
                    position: absolute;
                    left: 144px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .moreText {
                        color: white;
                        font-size: 28px;
                        font-weight: 400;
                    }
                }
            }

            .conditionText {
                margin: 12px 8px 0 8px;
                font-size: 14px;
                font-weight: 300;
                color: #1d3053;
            }

            .warningText {
                font-size: 11px;
                font-weight: 300;
                color: rgba(#1d3053, 0.6);
                margin-top: 24px;
            }
        }

        .conditionNoInfoContainer {
            width: 100%;
            height: 60px;
            align-items: center;
            justify-content: center;

            .conditionNoInfo {
                margin: 0 8px;
                font-size: 13px;
                font-weight: 300;
                color: rgba(#000000, 0.7);
            }
        }
    }

    .commentContainer {
        margin-top: 24px;
        border-top: 1px solid #E0E0E0;
        width: 100%;

        .commentInputContainer {
            width: 100%;
            height: 120px;
            margin-top: 24px;

            :global(.MuiFilledInput-root) {
                padding: 9px 12px;
                font-size: 13px;
                font-weight: 200;
            }

            .commentInput {
                width: 100%;    
            }
        }

        .postCommentContainer {
            justify-content: flex-end;
            margin-top: 5px;

            .postCommentButton {
                border: 1px solid #1d3053;
                border-radius: 2px;
                background-color: #1d3053;
                padding: 8px;

                .postCommentText {
                    font-size: 13px;
                    font-weight: 300;
                    color: white;
                    text-transform: none;
                }
            }
        }
    }

    .endSoldContainer {
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .winnerContainer {
            border: 1px rgb(46, 125, 50) solid;
            border-radius: 3px;
        }

        .winnerText {
            color: rgb(46, 125, 50);
            font-size: 14px;
            font-weight: 500;
            margin: 8px 8px 12px 8px;
        }

        .auctionEndedContainer {
            margin-left: 16px;

            .auctionEndedLabel {
                text-transform: none;
                font-size: 11px;
                font-weight: 400;
                color: rgba(#1D3053C7, 0.78);
                margin-top: 6px;
                margin-bottom: 4px;
            }

            .auctionEndedValue {
                color: #000000;
                font-size: 16px;
                font-weight: 300;
            }        
        }
    }
}

.bidErrorLabel {
    color: rgb(211, 47, 47) !important;
    text-decoration-color: rgb(211, 47, 47) !important;
}

.bidErrorLink {
    font-weight: 600 !important;
    color: rgb(211, 47, 47) !important;
    text-decoration-color: rgb(211, 47, 47) !important;
}