.root {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 36px;
            font-weight: 400;
            color: #4D5B77;
            text-transform: none;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: min(90%, 950px);
        height: max(70vh, 200px);

        .gridContainer {
            width: 100%;
            height: 100%;
        }
    }
}
