.root {
    border: 1px solid #cac4d0 !important;
    border-radius: 0 !important;
    margin-left: 4px !important;
    margin-right: 4px !important;
    padding: 0 !important;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    width: calc(100% - 8px);

    :global(.MuiTypography-root) {
        text-align: left;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        margin: 8px 12px;
        width: calc(100% - 24px);

        .brandContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            .brandTitle {
                font-size: 14px;
                font-weight: 400;
                color: #1d3053;
                flex-grow: 1;
                text-transform: uppercase;
            }

            .favouriteContainer {
                color: #1d3053;

                .favouriteIcon {
                    width: 16px;
                    height: 16px;
                    color: #1d3053;
                }
            }
        }

        .modelContainer {
            display: flex;
            flex-direction: row;

            .modelTitle {
                font-size: 13px;
                font-weight: 200;
                color: rgba(#000000, 0.6);
                text-transform: uppercase;                
            }
        }

        .yearContainer {
            display: flex;
            flex-direction: row;
            margin-top: 4px;

            .yearTitle {
                font-size: 12px;
                font-weight: 400;
                color: rgba(#000000, 0.6);
                flex-grow: 1;
            }
        }

        .watchImageContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            margin-top: 12px;

            .watchImage {
                width: 100%;
            }

            .watchNoImageContainer {
                width: 100%;
                height: 105.33px;
                justify-content: center;
                align-items: center;

                & svg {
                    color: #B5B5B5;
                }

                .watchNoImage {
                    width: 150px;
                }

                .watchNoImageText {
                    color: #B5B5B5;
                }
            }            
        }

        .titleAndValueContainer {
            justify-content: center;
        }

        .titleAndValueContainerGrow {
            justify-content: center;
            flex-grow: 1;
        }

        .bidsContainer {
            justify-content: center;
            margin-top: 13px;
        }

        .currentBidContainer {
            justify-content: left;
            margin-top: 12px;
        }

        .titleLabel {
            font-size: 12px;
            font-weight: 400;
            color: rgba(#1D3053C7, 0.78);
            text-align: center;
            text-transform: none;
        }

        .valueContainer {
            align-items: center;
            justify-content: center;
        }

        .valueLabel {
            font-size: 12px;
            font-weight: 300;
            color: #111D32;
            text-align: center;
        }

        .noBidsText {
            font-size: 12px;
            font-weight: 300;
            color: #111D32;
            text-align: center;
        }

        .spacer {
            flex-grow: 1;
        }

        .infoContainer {
            display: flex;
            flex-direction: row;
            margin-top: 12px;
            width: 100%;

            :global(.MuiIconButton-root) {
                padding: 0 !important;
            }
        }

        .endTimeContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #e02f29;

            .timerIcon {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }

            .auctionEndValue {
                font-size: 13px;
                font-weight: 300;
                color: #e02f29;
                text-align: center;
            }    
        }
    }
}